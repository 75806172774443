import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import moment from "moment";
import momentTimezone from "moment-timezone";
import en from "moment/locale/en-gb";
import fr from "moment/locale/fr";
import polyglotI18nProvider from "ra-i18n-polyglot";

/**
 * Translation. See https://marmelab.com/react-admin/Translation.html
 * https://github.com/i18next/react-i18next
 */
moment.updateLocale("en", en);
moment.updateLocale("fr", fr);
export default (locales) => {
    const getBrowserLocale = () => {
        const browserLocale = navigator.language.split("-")[0];
        return ["fr", "en"].includes(browserLocale)
            ? browserLocale
            : locales.defaultLocale;
    };

    const initialLocale =
        localStorage.getItem("locale_user") || getBrowserLocale();

    const polyglotProvider = polyglotI18nProvider(
        (locale) => locales[locale] || locales[locales.defaultLocale],
        initialLocale
    );

    i18n.use(initReactI18next).init({
        resources: {
            en: {
                translation: locales.en,
            },
            fr: {
                translation: locales.fr,
            },
        },
        lng: initialLocale,
        fallbackLng: locales.defaultLocale,
        interpolation: {
            escapeValue: false,
            format: (value, format, locale) => {
                if (format === "uppercase") return value.toUpperCase();
                if (value instanceof Date) {
                    return moment(value).format(format);
                }
                return value;
            },
        },
    });

    moment.locale(initialLocale);
    momentTimezone.defineLocale(initialLocale, moment.localeData()._config);
    momentTimezone.locale(initialLocale);
    i18n.on("languageChanged", (lng) => {
        moment.locale(lng);
        momentTimezone.defineLocale(lng, moment.localeData()._config);
        momentTimezone.locale(lng);
    });
    return {
        translate: (key, options) => {
            let translated = i18n.t(key, options);
            if (translated.indexOf("|||") !== -1) {
                translated = polyglotProvider.translate(key, options);
            }
            return translated;
        },
        changeLocale: (locale) => {
            polyglotProvider.changeLocale(locale);
            localStorage.setItem("locale_user", locale);
            return i18n.changeLanguage(locale);
        },
        getLocale: () => {
            return i18n.language;
        },
    };
};
