// in src/App.js
import React from "react";
// eslint-disable-next-line no-unused-vars
import * as _ from "intersection-observer"; // necessary for safari v12.0 and `useInView`
import { Resource, cacheDataProviderProxy } from "react-admin";
import CoreAdmin from "../ra-core-custom/CoreAdmin";
import CustomLayout from "./layout/Layout";
import LoginPage from "../layout/LoginPage";
import theme from "./theme";
import restProvider, { setLocale } from "../restProvider";
import authProvider, {
    setAuthContext,
    AUTH_CONTEXT_FRONT,
    AUTH_CONTEXT_ADMIN,
} from "../authProvider";
import routes from "./routes";
import * as locales from "./i18n";
import NotFound from "./pages/NotFound";
import i18nextProvider from "../i18n/i18nextProvider";
import { createHashHistory } from "history";
import ReactGA from "react-ga";
import querystring from "query-string";
import loadScript from "../front/components/LoadScript";

let AppLayout = CustomLayout;
const queryParams = querystring.parse(window.location.search, {
    ignoreQueryPrefix: true,
});
const adminContext = queryParams.context === AUTH_CONTEXT_ADMIN;
if (adminContext) {
    setAuthContext(AUTH_CONTEXT_ADMIN);
} else {
    setAuthContext(AUTH_CONTEXT_FRONT);
}

const i18nProvider = i18nextProvider(locales);
const storedLocale = localStorage.getItem("locale_user");
const browserLocale = navigator.language.split("-")[0];
const initialLocale = storedLocale || browserLocale || locales.defaultLocale;
setLocale(initialLocale);
const locale = initialLocale;
const history = createHashHistory();
localStorage.setItem("locale_user", locale);

const analyticsId = process.env.REACT_APP_ANALYTICS_ID;
const cookieControlApiKey = process.env.COOKIE_CONTROL_API_KEY;
if (analyticsId) {
    window.axeptioSettings = {
        clientId: "615d50ad9339246675b2c851",
    };

    let unlisten = () => {};

    if (locale === "en") {
        window.axeptioSettings.cookiesVersion = "Tastevin_en";
    } else {
        window.axeptioSettings.cookiesVersion = "Tastevin";
    }
    var t = document.getElementsByTagName("script")[0],
        e = document.createElement("script");
    e.async = true;
    e.src = "//static.axept.io/sdk.js";
    t.parentNode.insertBefore(e, t);

    unlisten = history.listen((location) => {
        ReactGA.pageview(location.pathname);
    });
    ReactGA.initialize(analyticsId);
    if (typeof window !== "undefined") {
        ReactGA.pageview(
            window.location.pathname +
                window.location.search +
                window.location.hash
        );
    }
}

const changeLocale = i18nProvider.changeLocale;
i18nProvider.changeLocale = (locale) => {
    const langue = locale;
    const localStorageLocale = localStorage.getItem("locale_user");
    const browserLocale = navigator.language.split("-")[0]; // Récupère 'fr' de 'fr-FR'
    setLocale(browserLocale);
    if (langue === "en") {
        window.axeptioSettings.cookiesVersion = "Tastevin_en";
    } else {
        window.axeptioSettings.cookiesVersion = "Tastevin";
    }
    setLocale(locale);
    changeLocale(locale);
};

const Front = () => {
    return (
        <CoreAdmin
            history={history}
            title="Confrérie des Chevaliers du Tastevin"
            theme={theme}
            customRoutes={routes}
            dataProvider={cacheDataProviderProxy(restProvider)}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
            loginPage={LoginPage}
            layout={AppLayout}
            catchAll={NotFound}
        >
            {() => [
                <Resource name="inductionrequest" key="inductionrequest" />,
                <Resource name="membre" key="membre" />,
                <Resource name="membrepreferences" key="membrepreferences" />,
                <Resource name="mecenat" key="mecenat" />,
                <Resource name="user" key="user" />,
                <Resource name="grade" key="grade" />,
                <Resource name="hotel" key="hotel" />,
                <Resource name="pays" key="pays" />,
                <Resource name="chapitreweb" key="chapitreweb" />,
                <Resource name="venue" key="venue" />,
                <Resource name="postjournal" key="postjournal" />,
                <Resource name="chapitre" key="chapitre" />,
                <Resource name="media" key="media" />,
                <Resource name="notification" key="notification" />,
                <Resource name="souscommanderie" key="souscommanderie" />,
                <Resource name="reservationweb" key="reservationweb" />,
                <Resource name="page" key="page" />,
                <Resource name="fichier" key="fichier" />,
            ]}
        </CoreAdmin>
    );
};

export default Front;
