import React from "react";
import { useTranslate, useLocale, useSetLocale } from "react-admin";
import Button from "@material-ui/core/Button";
import ReactCountryFlag from "react-country-flag";
import { getCountryCode } from "../admin/components/fields/I18nFieldsInput";
import makeStyles from "@material-ui/core/styles/makeStyles";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        "& .MuiButton-root": {
            minWidth: ({ hideText }) => (hideText ? 0 : null),
        },
    },
    flag: {
        marginRight: ({ hideText }) => (hideText ? 0 : theme.spacing(1)),
    },
}));

const LocaleSwitcher = ({
    hideText = false,
    buttonProps = null,
    className = null,
    ...props
}) => {
    const translate = useTranslate();
    const locale = useLocale();
    const setLocale = useSetLocale();
    const classes = useStyles({ hideText });
    return (
        <div className={classnames(className, classes.root)} {...props}>
            {locale === "fr" ? (
                <Button
                    color="primary"
                    onClick={() => {
                        localStorage.setItem("locale_user", "en"); //  L'utilisateur a changé la langue
                        setLocale("en");
                    }}
                    {...buttonProps}
                >
                    <ReactCountryFlag
                        countryCode={getCountryCode("en")}
                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/"
                        svg
                        className={classes.flag}
                    />
                    {hideText ? null : translate("Anglais")}
                </Button>
            ) : (
                <Button
                    color="primary"
                    onClick={() => {
                        localStorage.setItem("locale_user", "fr"); //  L'utilisateur a changé la langue
                        setLocale("fr");
                    }}
                    {...buttonProps}
                >
                    <ReactCountryFlag
                        countryCode={getCountryCode("fr")}
                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/4x3/"
                        svg
                        className={classes.flag}
                    />
                    {hideText ? null : translate("Français")}
                </Button>
            )}
        </div>
    );
};

export default LocaleSwitcher;
